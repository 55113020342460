<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :items="items"
    :total-registros="totalRegistros"
    :paginacao.sync="pagination"
    :hide-headers="$vuetify.breakpoint.smAndDown"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template
      slot="items"
      slot-scope="{ item }"
    >
      <tr>
        <td
          class="hidden-sm-and-down justify-left btnEditar cursorPointer"
          @click="editar(item.item)"
        >
          {{ item.item.nome }}
          <v-icon small>
            mdi-pencil
          </v-icon>
        </td>
        <td
          class="hidden-md-and-up justify-left btnEditar cursorPointer"
          @click="editar(item.item)"
        >
          <div class="mb-2">
            <v-icon small>
              mdi-pencil
            </v-icon>
            <span class="nome-cliente ml-2">{{ item.item.nome }}</span>
          </div>
          <v-icon
            v-if="item.item.telefone1"
            small
          >
            mdi-phone-classic
          </v-icon>
          <span
            v-if="item.item.telefone1"
            class="ml-2"
          >{{ item.item.telefone1 }}</span>
          <br v-if="item.item.email1 && item.item.telefone1">
          <v-icon
            v-if="item.item.email1"
            small
          >
            mdi-email-outline
          </v-icon>
          <span
            v-if="item.item.email1"
            class="ml-2"
          >{{ item.item.email1 }}</span>
        </td>
        <td class="hidden-sm-and-down justify-center">
          {{ item.item.telefone1 }}
        </td>
        <td class="hidden-sm-and-down justify-center">
          {{ item.item.email1 }}
        </td>
        <td class="hidden-sm-and-down justify-left">
          {{ item.item.cpfOuCnpj }}
        </td>
      </tr>
    </template>
  </crud-list>
</template>

<script>

  import Servico from '@/services/EmpresaClientesService'

  export default {
    data: () => ({
      totalRegistros: 0,
      pagination: {},
      items: [],
      loading: false,
      itemRemover: {}
    }),
    computed: {
      tituloPesquisa: function () {
        return this.$i18n.t('EmpresaClientesForm.tituloPesquisa')
      },
      headers: function () {
        return [
          {
            text: this.$i18n.t('EmpresaClientesForm.nome'),
            value: 'nome'
          },
          {
            text: this.$i18n.t('EmpresaClientesForm.telefone1'),
            value: 'telefone1',
            class: 'hidden-sm-and-down',
            sortable: false
          },
          {
            text: this.$i18n.t('EmpresaClientesForm.email1'),
            value: 'telefone1',
            class: 'hidden-sm-and-down',
            sortable: false
          },
          {
            text: this.$i18n.t('EmpresaClientesForm.cpfOuCnpj'),
            value: 'telefone1',
            class: 'hidden-sm-and-down',
            sortable: false
          }
        ]
      }
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', true)
      this.$root._events.pesquisa = []
      this.$root.$on('pesquisa', this.efetuarPesquisa)
    },
    methods: {
      efetuarPesquisa (pesquisa) {
        const param = {
          nome: pesquisa
        }
        this.loading = true
        Servico.findByParam(param, this.pagination)
          .then((dados) => {
            if (dados && dados.data && dados.data.rows) {
              this.items = dados.data.rows
            }
            if (dados && dados.data) {
              this.totalRegistros = dados.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + '\n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      buscar () {
        this.loading = true
        Servico.findAll(this.pagination)
          .then((dados) => {
            if (dados && dados.data && dados.data.rows) {
              this.items = dados.data.rows
            }
            if (dados && dados.data) {
              this.totalRegistros = dados.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + '\n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      novo () {
        this.$router.push({
          path: '/empresaClientesForm'
        })
      },
      editar (item) {
        if (item && item.id) {
          const url = '/empresaClientesForm/' + item.id
          this.$router.push(url)
        }
      }
    }
  }
</script>

<style>
.nome-cliente {
  font-weight: bold;
}
</style>
